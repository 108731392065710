<template>
  <div class="outBox" :class='padding ? "s_ptb0lr20":""'  >
    <div class="insideBox s_flex_ali" :style="{'background' : color}">
      <p v-html="content"></p>
    </div>
  </div>
</template>

<script>
  import { reactive, toRefs } from "vue";
  export default {
    name: "SmallSquare",
    props: {
      content: {
        type: String
      },
      padding: {
        type: Boolean
      },
      color:String
    },
    setup(props) {

    }
  }
</script>