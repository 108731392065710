import store from '@/store'
import {message} from 'ant-design-vue';
const authAction = {
  // 初始化指令
  mounted(el, binding,vnode) {
    var arg=binding.arg
    var auths=store.state.user.info.auths
    // 获取按钮权限
    if (!auths.includes(arg)) {
      el.setAttribute('disabled',true);
     //移除不匹配的按钮
    }
    const documentHandler = (e) => {

      e.stopPropagation()
      if (!el.contains(e.target)) return
      if(!auths.includes(arg)){
          message.error('抱歉你暂无该权限,请联系管理员')
          return
      }
      if(binding.value){
        binding.value(e)
      }

    }
    el.__vueClickOutside__ = documentHandler
    document.addEventListener('click', documentHandler)
  },
    /**
   * 销毁方法
   * @param {Object} el - The element the directive is bound to.
   */
     unbind(el) {
      document.removeEventListener('click', el.__vueClickOutside__)
      delete el.__vueClickOutside__
    },
}
export default authAction



