<template>
  <a-sub-menu :key="menusKey" :menusKey="menusKey">
    <template v-slot:title>
      <span>
        <component v-if='item.icon' :is="item.icon"></component><span>{{item.title}}</span>
      </span>
    </template>
      <template v-for='(itemChild) of item.children'>
        <sub-menu v-if="itemChild.children && itemChild.children.length > 0 &&  itemChild.children.length-itemChild.children.filter(v=>v.hidden).length>0" :key='itemChild.key' :menusKey="itemChild.key" :item='itemChild'></sub-menu>
        <template v-else>
          <menu-item v-if="!itemChild.hidden" :key='itemChild.key' :menusKey="itemChild.key" :item='itemChild'></menu-item>
        </template>
      </template>
  </a-sub-menu>
</template>
<script>
import * as Icon from "@ant-design/icons-vue";
import MenuItem from "./MenuItem.vue";
export default {
  name: "SubMenu",
  components: {
    MenuItem,
    ...Icon,
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    menusKey:String
  },
  data() {
    return {};
  },
};
</script>