import {message} from 'ant-design-vue';
let __router;
export const setRouter = (router,route)=>{
  __router = {
    router: router,
    route: route
  };
}
export const getRouter = ()=>{
  return __router;
}
export const $iscode = (res, isShowSussessMessage)=>{
  if(!res)return
  if(res.code === 1){
    isShowSussessMessage && message.success(res.msg);
    return true;
  } else if(res.code!=-14&&res.code!=-1) {
    if(res.msg){
      isShowSussessMessage && message.error(res.msg);
    }
    return false;
  }else if(res.code<1){
    if(res.msg){
      isShowSussessMessage && message.error(res.msg);
    }
    return false;
  }
}