/**
 * ①添
 * @@新增 定义初始化菜单
 */
import lazyLoading from './lazy-loading'
import layout from '@/layout/DefaultLayout' // Main 是架构组件，不在后台返回，在文件里单独引入
// 加载路由菜单,从localStorage拿到路由,在创建路由时使用
import menu from '@/config/menu'
import store from '@/store'
export const dynamicRouterAdd = () => {
  let userInfo = store.state.user.info;
  let data=[]
  if (userInfo && userInfo.menu && userInfo.menu.length>0) {
     data = userInfo.menu 
  }
  if (!data) {
    return []
  }
  return dataFilter([],filterAsyncRouter(data))
}
// 数据扁平
const dataFilter=(temp,arr)=>{
    arr.forEach(element => {
       if(element.children){
         if(element.component){
              temp.push(element)
         }
        return dataFilter(temp,element.children)
       }else{
        temp.push(element)
       }
    });
    return temp
}
// @函数: 遍历后台传来的路由字符串，转换为组件对象
export const filterAsyncRouter = (asyncRouterMap) => {
  let accessedRouters = []
  if (asyncRouterMap) {
    asyncRouterMap.filter(route => {
      let accessedRouter = {}
      // accessedRouter.path = route.router
      accessedRouter.path = route.key
      accessedRouter.key = route.key
      accessedRouter.name = route.title
      accessedRouter.meta = {
        icon: route.icon,
        title: route.title,
        hidden: route.hidden
      }
      if (route.component === '') {
        accessedRouter.component = layout
      } else {
        if (route.component) {
          accessedRouter.component =()=>import(`@/views/${route.component}.vue`)
        }
      }
      if (route.children && route.children.length) {
        accessedRouter.children = filterAsyncRouter(route.children)
      }
      accessedRouters.push(accessedRouter)
    })
  }
  return accessedRouters
}
