import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import 'animate.css'
import './style/base.less'
import './style/App.less'
import App from './App.vue'
import router from './routers'
import './permission' 
import store from './store'
import SlideVerify from 'vue-monoplasty-slide-verify';
import inputFilter from '@/core/directive/input.js' //输入框限制指令
// 注册指令
import has from '@/core/directive/has.js'  //权限指令
import authAction from '@/core/directive/authAction.js'  //权限指令
const app=createApp(App)
// 注册通用组件
const requireComponent = require.context(
  // 其组件目录的相对路径
  './components',
  // 是否查询其子目录
  true,
  // 匹配基础组件文件名的正则表达式
  /\.vue$/
)
requireComponent.keys().forEach(fileName => {
  // 获取组件配置
  const componentConfig = requireComponent(fileName)
  // 全局注册组件
  app.component(
    componentConfig.default.name, 
    // 如果这个组件选项是通过 `export default` 导出的，
    // 那么就会优先使用 `.default`，
    // 否则回退到使用模块的根。
    componentConfig.default || componentConfig
  )
})
app.use(Antd).use(SlideVerify).use(store).use(router).directive('has',has).directive('authAction',authAction).directive('inputFilter',inputFilter).mount('#root')