//品牌商api

import axios from '@/utils/request'
// 获取品牌商列表
export const getBrandtList = (params)=>{
 return axios.request({
    url: 'brand/lists',
    method: 'get',
    params
  })
};
// 添加品牌商
export const brand_create = (data)=>{
  return axios.request({
     url: 'sysBrand/create',
     method: 'post',
     data
   })
 };
 // 修改品牌商
export const brand_update = (data)=>{
  return axios.request({
     url: 'sysBrand/update',
     method: 'post',
     data
   })
 };
// 添加品牌商-可用年限
export const brandAppYears = (params)=>{
  return axios.request({
     url: 'sysBrand/appYears',
     method: 'get',
     params
   })
 };


 // 通过应用ID获取套餐年限
export const getBrandAppByYear = (params)=>{
  return axios.request({
     url: 'sysBrand/yearsByApp',
     method: 'get',
     params
   })
 };
 

 // 行业
export const getBrandIndustrys = (params)=>{
  return axios.request({
     url: 'sysBrand/industry',
     method: 'get',
     params
   })
 };

// 获取品牌商详情
export const getBrandtMore = (id)=>{
  return axios.request({
     url: 'brand/detail/'+id,
     method: 'get'
   })
 };
// 获取品牌商审核详情
export const getAuditMore = (id)=>{
  return axios.request({
     url: 'brand/initAuditInfo',
     method: 'get',
     params:{id}
   })
 };
 
//品牌商禁用
export const setBrandDisable = (id,status)=>{
  return axios.request({
     url: 'brand/status/'+id+'/'+status,
     method: 'PUT'
   })
};
//品牌商充值
export const setBrandRecharge = (data)=>{
  return axios.request({
     url: 'brand/bill/recharge',
     method: 'POST',
     data
   })
};
//品牌商扣款
export const setBrandDeduct= (data)=>{
  return axios.request({
     url: 'brand/bill/deduct',
     method: 'POST',
     data
   })
};
//品牌商 - 设置佣金
export const setBrandCommission = (data)=>{
  return axios.request({
     url: 'brand/setCommission',
     method: 'POST',
     data
   })
};
//品牌商 - 获取佣金详情
export const getBrandCommission = (params)=>{
  return axios.request({
     url: 'brand/getCommission',
     method: 'get',
     params
   })
};
// 获取品牌商禁用审核列表
export const setBrandtDisableReview = (data)=>{
  return axios.request({
     url: 'brand/banAudit',
     method: 'PUT',
     data
   })
};
// 获取品牌商禁用审核列表
export const getBrandtDisableList = (params)=>{
  return axios.request({
     url: 'brand/banAuditLists',
     method: 'get',
     params
   })
};

// 获取品牌商修改付费模式审核列表
export const systemFeesTypeApplyLists = (params)=>{
  return axios.request({
     url: 'system/brandApps/updateFeesTypeApplyLists',
     method: 'get',
     params
   })
};
// 品牌商修改付费模式审核
export const auditFeesTypeApply = (data)=>{
  return axios.request({
     url: 'system/brandApps/auditFeesTypeApply',
     method: 'post',
     data
   })
};
// 获取品牌商开户审核列表
export const getBrandtInitAuditLists = (params)=>{
  return axios.request({
     url: 'brand/initAuditLists',
     method: 'get',
     params
   })
};
// 获取品牌商开户审核
export const setBrandtInitAudit = (data)=>{
  return axios.request({
     url: 'brand/initAudit',
     method: 'post',
     data
   })
};



// 获取短信模板审核列表
export const getSmstplList = (params)=>{
  return axios.request({
     url: 'brand/smstpl/lists',
     method: 'get',
     params
   })
};
// 短信模板提交至阿里
export const setSmstpl = (data)=>{
  return axios.request({
     url: 'brand/smstpl/sendAudit/'+data.id,
     method: 'POST',
     data
   })
};
// 短信模板 - 设置系统状态
export const setSmstplStatus = (id,status,data)=>{
  return axios.request({
     url: 'brand/smstpl/setSysStatus/'+id+'/'+status,
     method: 'PUT',
     data
   })
};
// 短信模板 - 刷新查询阿里审核状态
export const smstplRefresh = ()=>{
  return axios.request({
     url: 'brand/smstpl/queryAuditStatus',
     method: 'POST'
   })
};

// 获取短信签名审核列表
export const getSignedList = (params)=>{
  return axios.request({
     url: 'brand/smsSign/lists',
     method: 'get',
     params
   })
};
// 短信签名提交至阿里
export const setSigned = (id)=>{
  return axios.request({
     url: 'brand/smsSign/sendAudit/'+id,
     method: 'POST'
   })
};
// 短信签名 - 设置系统状态
export const setSignedStatus = (id,status,data)=>{
  return axios.request({
     url: 'brand/smsSign/setSysStatus/'+id+'/'+status,
     method: 'PUT',
     data
   })
};
// 短信签名 - 刷新查询阿里审核状态
export const SignedRefresh = ()=>{
  return axios.request({
     url: 'brand/smsSign/queryAuditStatus',
     method: 'POST'
   })
};

// 资质认证 - 列表
export const getQualified = (params)=>{
  return axios.request({
     url: 'brand/cert/lists',
     method: 'get',
     params
   })
};
// 资质认证 - 审核
export const statusQualified = (data)=>{
  return axios.request({
     url: 'brand/cert/status',
     method: 'POST',
     data
   })
};

/**
 * 消息订阅模板
 */

// 消息订阅 - 内容列表
export const getMessProject = (params)=>{
  return axios.request({
     url: 'brand/messProject',
     method: 'get',
     params
   })
};
// 消息订阅 - 消息类型列表
export const getMessProjectType = (params)=>{
  return axios.request({
     url: 'brand/messProject/create',
     method: 'get',
     params
   })
};
// 消息订阅 - 添加内容
export const messProjectAdd = (data)=>{
  return axios.request({
     url: 'brand/messProject',
     method: 'POST',
     data
   })
};
//消息订阅 - 修改内容
export const messProjectEdit = (data)=>{
  return axios.request({
     url: 'brand/messProject/'+data.id,
     method: 'PUT',
     data
   })
};
// 消息订阅 - 内容详情
export const getMessProjectMore = (id)=>{
  return axios.request({
     url: 'brand/messProject/'+id,
     method: 'get'
   })
};
//消息订阅 - 状态设置
export const messProjectStatus = (data)=>{
  return axios.request({
     url: 'brand/messProject/'+data.id+'/'+data.status,
     method: 'PUT'
   })
};
//消息订阅 - 删除
export const messProjectDel = (id)=>{
  return axios.request({
     url: 'brand/messProject/'+id,
     method: 'DELETE'
   })
};


/**
 * 消息推送
 */

// 消息推送 - 内容列表
export const getMessPush = (params)=>{
  return axios.request({
     url: 'brand/messPush',
     method: 'get',
     params
   })
};
// 消息订阅 - 消息类型列表
export const getMessPushType = (params)=>{
  return axios.request({
     url: 'brand/messPush/create',
     method: 'get',
     params
   })
};
// 消息订阅 - 添加内容
export const messPushAdd = (data)=>{
  return axios.request({
     url: 'brand/messPush',
     method: 'POST',
     data
   })
};
//消息订阅 - 修改内容
export const messPushEdit = (data)=>{
  return axios.request({
     url: 'brand/messPush/'+data.id,
     method: 'PUT',
     data
   })
};
// 消息订阅 - 内容详情
export const getMessPushMore = (id)=>{
  return axios.request({
     url: 'brand/messPush/'+id,
     method: 'get'
   })
};
//消息订阅 - 状态设置
export const messPushStatus = (data)=>{
  return axios.request({
     url: 'brand/messPush/'+data.id+'/'+data.status,
     method: 'PUT'
   })
};
//消息订阅 - 删除
export const messPushDel = (id)=>{
  return axios.request({
     url: 'brand/messPush/'+id,
     method: 'DELETE'
   })
};

// 续费
export const appRenewal = (data)=>{
  return axios.request({
     url: 'sysBrand/appRenewal',
     method: 'POST',
     data
   })
};

// 切换收费模式
export const updateAppfeesType = (data)=>{
  return axios.request({
     url: 'sysBrand/updateAppfeesType',
     method: 'POST',
     data
   })
};

// 续费管理 - 使用中的应用列表
export const getUsingApps = (params)=>{
  return axios.request({
     url: 'sysBrand/usingAppsForBrand',
     method: 'GET',
     params
   })
};

// 续费管理 - 应用获取套餐
export const appsByInfoId = (params)=>{
  return axios.request({
     url: 'sysBrand/appsByInfoId',
     method: 'GET',
     params
   })
};


// 升级管理 - 应用获取套餐
export const unUsingAppsForBrand = (params)=>{
  return axios.request({
     url: 'sysBrand/unUsingAppsForBrand',
     method: 'GET',
     params
   })
};
// 升级管理 - 升级
export const appBuy = (data)=>{
  return axios.request({
     url: 'sysBrand/appBuy',
     method: 'POST',
     data
   })
};

// 品牌商 -溯源提示模板审核列表
export const antisFakeTemList = (params)=>{
  return axios.request({
     url: 'brand/antis_fake_tem/lists',
     method: 'GET',
     params
   })
};

// 品牌商 -溯源提示模板审核列表 保存
export const antisFakeTemListSave = (data)=>{
  return axios.request({
     url: 'brand/antis_fake_tem/save',
     method: 'post',
     data
   })
};

// 系统公告列表
export const noticeList = (params)=>{
  return axios.request({
     url: 'notice/index',
     method: 'get',
     params
   })
};

// 添加系统公告
export const noticeAdd = (data)=>{
  return axios.request({
     url: 'notice/add',
     method: 'post',
     data
   })
};

// 编辑系统公告
export const noticeEdit = (data)=>{
  return axios.request({
     url: 'notice/update/'+data.id,
     method: 'post',
     data
   })
};

// 系统公告详情
export const noticeDetail = (params)=>{
  return axios.request({
     url: 'notice/detail/'+params.id,
     method: 'get',
     params
   })
};

// 已选品牌商
export const noticeBrands = (params)=>{
  return axios.request({
     url: 'notice/brands/'+params.id,
     method: 'get',
     params
   })
};


//系统公告图片上传
export const imgUpload = (data)=>{
  return axios.request({
     url: 'upload/upload',
     method: 'post',
     data
   })
};

//系统公告删除
export const noticeDel = (params)=>{
  return axios.request({
     url: 'notice/del/'+params.id,
     method: 'DELETE',
     params
   })
};


//品牌商导出
export const brandExportLists = (params)=>{
  return axios.request({
     url: 'brand/exportLists',
     method: 'get',
     params
   })
};

//品牌商 - 登录控制
export const brandLoginControl= (data)=>{
  return axios.request({
     url: 'brand/loginControl',
     method: 'post',
     data
   })
};
//品牌商 -  强制登出
export const brandForcedLogout= (data)=>{
  return axios.request({
     url: 'brand/forcedLogout',
     method: 'post',
     data
   })
};


// 系统图片公告列表
export const noticeImgList = (params)=>{
  return axios.request({
     url: 'advertising/index',
     method: 'get',
     params
   })
};

// 添加图片系统公告
export const noticeImgAdd = (data)=>{
  return axios.request({
     url: 'advertising/add',
     method: 'post',
     data
   })
};

// 编辑图片系统公告
export const noticeImgEdit = (data)=>{
  return axios.request({
     url: 'advertising/update/'+data.id,
     method: 'post',
     data
   })
};

// 图片系统公告详情
export const noticeImgDetail = (params)=>{
  return axios.request({
     url: 'advertising/detail/'+params.id,
     method: 'get',
     params
   })
};

//图片系统公告删除
export const noticeImgDel = (data)=>{
  return axios.request({
     url: 'advertising/del/'+data.id,
     method: 'DELETE',
     data
   })
};

// 图片系统公告状态更新
export const noticeImgStatus = (data)=>{
  return axios.request({
     url: 'advertising/status/'+data.id,
     method: 'post',
     data
   })
};
// 图片系统公告排序
export const noticeImgSort = (data)=>{
  return axios.request({
     url: 'advertising/sort/'+data.id,
     method: 'post',
     data
   })
};
// 短信签名 - 详情
export const getsignDetail = (data)=>{
  return axios.request({
     url: 'brand/smsSign/detail/'+data.id,
     method: 'get',
     data
   })
};

// 图片系统公告-已选品牌商
export const noticeImgBrands = (params)=>{
  return axios.request({
     url: 'advertising/brands/'+params.id,
     method: 'get',
     params
   })
};

// 获取品牌码库管理列表
export const getBrandtCodeList = (params)=>{
  return axios.request({
     url: 'v2/code_num/list',
     method: 'get',
     params
   })
 };
 // 品牌码库管理-充值
export const BrandtCodeAdd = (data)=>{
  return axios.request({
     url: 'v2/code_num/recharge',
     method: 'post',
     data
   })
};
// 获取品牌码库管理-充值明细
export const getGenerationDetails = (params)=>{
  return axios.request({
     url: 'v2/code_num/recharge_log',
     method: 'get', 
     params
   })
 };
 // 获取品牌码库管理-生码明细
export const getRecharge = (params)=>{
  return axios.request({
     url: 'v2/code_num/recharge_log',
     method: 'get',
     params
   })
 };
  // 短信模板 - 详情
export const smstplDetail = (params)=>{
  return axios.request({
     url: 'brand/smstpl/detail/'+params.id,
     method: 'get',
     params
   })
 };
  // 品牌-短信充值
export const brandSms = (data)=>{
  return axios.request({
     url: 'v2/sms',
     method: 'post',
     data
   })
};
 