const addListener = function(el, type, fn) {
  el.addEventListener(type, fn, false)
}
//去掉空格
const spaceFilter = function(el) {
  addListener(el, 'input', () => {
    el.value = el.value.replace(/\s+/, '')
  })
}
// 防抖
let debounce = (fn, delay,el) => {
  var delays = delay || 100;
  var timer;
  return function() {
    var th = this;
    var args = arguments;
    if (timer) {
      document.removeEventListener('input', el)
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      timer = null;
      fn.apply(th, args);
    }, delays);
  };
}
// 限制只能输入整数和小数（价格类、两位小数）
const priceFilter = function(el) {
  addListener(el, 'input', debounce(() => {//添加防抖 防止反复触发事件导致内存溢出
    el.value = (el.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null
    if (isNaN(el.value)) {
      el.value = ''
    }
    //触发input事件
    el.dispatchEvent(new Event('input'))
    
  },500,el))
}

// 限制只能输入英文数字 英文符号 - _
const snFilter = function(el) {
  addListener(el, 'input', debounce(() => {//添加防抖 防止反复触发事件导致内存溢出
    el.value = el.value.match(/^[0-9a-zA-Z_]{1,}$/gi) || '' 
    //触发input事件
    el.dispatchEvent(new Event('input'))
  },500,el))
}
// 限制只能输入英文数字 英文符号 - _ 或者邮箱
const snemFilter = function(el) {
  addListener(el, 'input', debounce(() => {//添加防抖 防止反复触发事件导致内存溢出
    // console.log(el.value.text(/^[0-9a-zA-Z_]{1,}$/g),el.value.text(/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/))
    // var e_mail_reg=/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/g
    // var sn_reg=/^[0-9a-zA-Z_]{1,}$/g
    // if( e_mail_reg.text(el.value)){
    //   el.value=el.value.match(/^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/)[0]
    // }else if(sn_reg.text(el.value)){
 
    // }else{
    //   el.value=''
    // }
    el.value=el.value.match(/^[0-9a-zA-Z_@.]{1,}$/gi)||''
    //触发input事件
    el.dispatchEvent(new Event('input'))
  },500,el))
}
// 限制只能输入整数
const intFilter = function(el) {
  addListener(el, 'input', debounce(() => {//添加防抖 防止反复触发事件导致内存溢出
    el.value =el.value.replace(/[^\d]/g,'') 
    //触发input事件
    el.dispatchEvent(new Event('input'))
  },500,el))
}
export default {
  mounted(el, binding) {
    if (el.tagName.toLowerCase() !== 'input') {
      el = el.getElementsByTagName('input')[0]
    }
    spaceFilter(el)
    switch (binding.arg) {
      case 'price':
        priceFilter(el)
        break;
      case 'sn':
        snFilter(el)
        break;
        case 'snem':
        snemFilter(el)
        break;
        case 'int':
          intFilter(el)
        break;
      default:
        console.warn('未知指令类型',binding.arg)
        break
    }
  },
    /**
   * 销毁方法
   * @param {Object} el - The element the directive is bound to.
   */
  unbind(el) {
    el.value=''
  },
}