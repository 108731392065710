<template>
  <div ref="charts" style="width: 100%; height: 250px"></div>
</template>
<script>
import echarts from "echarts";
import { reactive, toRefs, ref, onMounted, inject } from "vue";
export default {
  name: "echartPie",
  props:{
    echartsData:{
        type:Object,
        default:()=>{}
    }
  },
  setup(props,{emit}) {
    const $router=inject("$router")
    const charts = ref(null);
    const state = reactive({
      myChart: null,
      option: {
        title: {
          show: true,
          text: "品牌商",
        },
        tooltip: {
          show: false,
        },
        legend: {
          type: "plain",
          show: true,
          orient: "vertical",
          right: 0,
          bottom: 0,
          selectedMode:false
        },
        graphic: {
          type: "text",
          left: "46%",
          top: "45%",
          style: {
            text: `总数\n${20}`,
            textAlign: "center",
            fill: "#333",
            fontSize: 16,
            align: "center",
          },
        },
        color: ["#ee6666",  "#3ba272","#5470c6"],
        series: [
          {
            name: "品牌商",
            type: "pie",
            radius: ["40%", "90%"],
            label: {
              show: true,
              position: "inner",
            //   formatter: "\n{b}\n{c}",
            formatter:function(params){
               if(params.percent === 0){
                 return ``
                  }else{
                return `\n${params.name}\n${params.value}`
                  }
            },
              align: "center",
              fontSize: 14,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "18",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 0, name: "已过期" },
              { value: 0, name: "续费期" },
              { value: 100, name: "正常期" },
            ],
          },
        ],
      },
    });

    const init = function (option) {
      state.myChart = echarts.init(charts.value);
      state.option["title"].text = option.name;
      state.option["graphic"].style["text"] = `总数\n${option.total || 0}`;
      let arr = [
              { value: 0, name: "其他" },
            ]
      state.option["series"][0].data = option.data || arr;
      state.myChart.setOption(state.option);
      state.myChart.on("click",function (param) {
          if(param.value==0) return
          $router.push(param.data.url)

        },true);
    };

        window.addEventListener("resize", function () {
           state.myChart.resize();
          });

        window.removeEventListener("resize", function () {
          state.myChart.resize();
        })

    onMounted(() => {
      init(props.echartsData);
    });

    return {
      ...toRefs(state),
      charts,
      init
    };
  },
};
</script>