const state = {
  token:'',
  info: {},
  menu:[],
  auth:[]
}
const mutations = {
  SET_USER_INFO(state, info){
    if(info){
      state.info = info;
    }else{
      state.info = {}
    }
  },
  SET_USER_TOKEN(state, token) {
    if(token){
      state.token = token;
    }else{
      state.token = ''
    }
  },
  SET_USER_MENU(state,menu){
    if(menu){
      state.menu = menu;
    }else{
      state.menu = []
    }
  },
  SET_USER_AUTH(state,auths){
    if(auths){
      state.auth = auths;
    }else{
      state.auth = []
    }
  }
}
const actions = {
  setUserToken({ commit }, token){
    return new Promise(resolve => {
      commit('SET_USER_TOKEN', token);
      resolve()
    })
  },
  setUserMenu({ commit }, menu){
    return new Promise(resolve => {
      commit('SET_USER_MENU', menu);
      resolve()
    })
  },
  setUserInfo({ commit }, info){
    return new Promise(resolve => {
      commit('SET_USER_INFO', info);
      resolve()
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
