<template>
  <a-config-provider :locale="zh_CN">
    <router-view />
  </a-config-provider>
</template>
<script>
import { mapState } from 'vuex';
import { setRouter } from '@/utils/app';
import { provide, ref, toRefs } from 'vue';
import {useRoute,useRouter} from 'vue-router'
import {message,Modal} from 'ant-design-vue';
import {useStore} from 'vuex'
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/dist/locale/zh-cn';

moment.locale('zh-cn');
export default {
  setup(){
    setRouter(useRouter(), useRoute());
    //  依赖注入
    // 路由
    provide('$router',useRouter())
    provide('$route',useRoute())
    // vuex
    provide('$store',useStore())
    // 蚂蚁UI 的方法
    provide('$message',message)
    provide('$Modal',Modal)
    // lodash 工具
    provide('_lodash',require('lodash'))
    // 时间格式化
    provide('momentTime', moment)
    return {
        zh_CN,
    }
  }
};
</script>