<template>
  <div ref="charts" style="width: 100%; height: 250px"></div>
</template>
<script>
import echarts from "echarts";
import { reactive, toRefs, ref, onMounted, inject } from "vue";
export default {
  name: "echartBar",
  props: {
    echartsData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const $router = inject("$router");
    const charts = ref(null);
    const state = reactive({
      myChart: null,
      option: {
        title: {
          show: true,
          text: "平台佣金",
        },
        tooltip: {
          show: false,
        },
        legend: {
          type: "plain",
          show: true,
          orient: "vertical",
          right: 0,
          bottom: 0,
          selectedMode: false,
        },
        grid:{
            width:'88%',
            height:'67%'
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            label: {
            show: true,
            position: "top",
            color: "#333",
            fontSize: "12px",
            formatter: (params) => {
              return `￥${params.value.toFixed(2)}`
            },
            },
            data: [],
            type: "bar",
            itemStyle: {
            normal: {
                color: function(params) {
                    var colorList = ['#ee6666','#5c7bd9', '#3ba272', '#80ffff', '#facd91'];
                    return colorList[params.dataIndex]
                }
            }
        }
          },
        ],
      },
    });

    const init = function (option) {
      state.myChart = echarts.init(charts.value);
      state.option["title"].text = option.name;
      state.option["series"][0].data = option.data || [];
      state.option["xAxis"].data = option.x || [];
      state.myChart.setOption(state.option);
    };

      window.addEventListener("resize", function () {
           state.myChart.resize();
          });

        window.removeEventListener("resize", function () {
          state.myChart.resize();
        })

    onMounted(() => {
      init(props.echartsData);
      //   methods()
    });

    return {
      ...toRefs(state),
      charts,
      init,
    };
  },
};
</script>