import {message} from 'ant-design-vue';
import store from '@/store'
const has = {
  // 初始化指令
  mounted(el, binding, vnode) {
    const documentHandler = (e) => {
      var arg=binding.arg
      var auths=store.state.user.info.auths
      if (!el.contains(e.target)) return
      if(!auths.includes(arg)){
          el.setAttribute('disabled',true);
          message.error('抱歉你暂无该权限,请联系管理员')
          return
      }
      if(binding.value){
        binding.value(e)
      }

    }
    el.__vueClickOutside__ = documentHandler
    document.addEventListener('click', documentHandler)
  },
  /**
   * 销毁方法
   * @param {Object} el - The element the directive is bound to.
   */
  unbind(el) {
    console.log(el)
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  },
}
export default has



