<template>
  <a-layout-header class='header' :style="{ position: 'fixed', zIndex: 3, width: '100%' }">
    <div class="logo">
            <img :src="Logo" alt="" />
            <!-- <span v-if='!menuToggle' class='ml15'>总后台</span> -->
    </div>
    <div class="left" :style='{width:"83%"}'>
      <MenuUnfoldOutlined v-if='menuToggle' @click="menuClick" />
      <MenuFoldOutlined v-else @click="menuClick" />
      <div :style='{marginLeft:"10px"}'>
        <header-breadcrumb :arr='breadCrumb'></header-breadcrumb>
      </div>
    </div>
    <div class="right">
      <div class="mr15" @click='screenfullChange'>
        <ShrinkOutlined v-if='isscreenfull' />
        <ArrowsAltOutlined v-else />
      </div>
      <div>
        <a-dropdown>
          <template v-slot:overlay>
            <a-menu :style="{ width: '20rem' }">
              <!-- <a-menu-item-group title='用户设置'>
                <a-menu-divider />
                <a-menu-item>
                  <SettingOutlined />
                  系统设置
                </a-menu-item>
              </a-menu-item-group> -->
              <!-- <a-menu-divider /> -->
              <a-menu-item>
                <div v-has:passWord="() => $router.push('/system/passWord')">
                  <lock-outlined /> 修改密码
                </div>
              </a-menu-item>
              <a-menu-item>
                <div @click='loginOut'>
                  <LogoutOutlined /> 退出登录
                </div>
              </a-menu-item>
            </a-menu>
          </template>
          <div class='ant-dropdown-link'>
            <a-avatar :src='avatar' alt='avatar' :style="{ cursor: 'pointer' }">
              <template v-slot:icon>
                <UserOutlined />
              </template>
            </a-avatar>
          </div>
        </a-dropdown>
      </div>
    </div>
  </a-layout-header>
</template>
<script>
// GlobalOutlined

import Logo from '../assets/images/logo2.png';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShrinkOutlined,
  ArrowsAltOutlined,
  EditOutlined,
  // SettingOutlined,
  LogoutOutlined,
  UserOutlined,
  LockOutlined
} from '@ant-design/icons-vue';
import HeaderBreadcrumb from './HeaderBreadcrumb';
import screenfull from 'screenfull';
export default {
  name: 'AppHeader',
  components: {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ShrinkOutlined,
    ArrowsAltOutlined,
    HeaderBreadcrumb,
    // GlobalOutlined,
    // SettingOutlined,
    LogoutOutlined,
    UserOutlined,
    LockOutlined
  },
  props: {
    menuClick: {
      required: true,
      type: Function,
    },
    loginOut: {
      required: true,
      type: Function,
    },
    menuToggle: {
      required: true,
      type: Boolean,
    },
    avatar: String,
    breadCrumb: Array,
  },
  data() {
    return {
            Logo,
      isscreenfull: false,
    };
  },
  methods: {
    screenfullChange() {
      if (!screenfull.isEnabled) {
        this.$message.warning('你的浏览器不支持全屏');
        return false;
      }
      this.screenfull = !this.isscreenfull;
      screenfull.toggle();
    },
  },
};
</script>