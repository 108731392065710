<template>
  <div ref="charts" style="width:100%;height:500px"></div>
</template>

<script>
  import echarts from "echarts";
  import { onMounted, ref,watchEffect,reactive,toRefs } from "vue";
  export default {
    name: "echartLine",
    props: {
      echartsData: {
        type: Object
      },
    },
    setup(props) {
      const state = reactive({
        lineData:{}
      })
      const charts = ref();
      watchEffect(() => {
        state.lineData = props.echartsData
        const chart = charts.value;
        if (chart) {
          var myChart = echarts.init(chart);
          const option = {
            tooltip: {
              trigger: 'axis'
            },
            legend: {               //设置区分（哪条线属于什么）
              data: [state.lineData.name],
              top: 'bottom'
            },
            grid:{
              x:60,
              x2:60
            },
            xAxis: {
              type: "category",
              data: state.lineData.dates
            },
            yAxis: {
              type: 'value'
            },
            series: [
              {
                name: state.lineData.name,
                data: state.lineData.counts,
                type: 'line',
                smooth: true,
                symbolSize: 6,
              },
            ],
          };
          myChart.setOption(option);

          window.addEventListener("resize", function () {
            myChart.resize();
          });
        }
        window.removeEventListener("resize", function () {
          myChart.resize();
        });

      });
      
      return {
        ...toRefs(state),
        charts,
      };
    },
  };
</script>