// http://192.168.0.74:9999// http://zht-api.test.2vm.co/
export default {
  baseUrl: {
    pro: "https://zht-api.hnsn.vip/",
    dev: "http://zht-api-dev.hnsn.2vm.co/",
    // dev: "https://zht-api.hnsn.vip/",
    uat: "http://zht-api.hnsn.2vm.co/"
  },
  oss: {
    dev: "https://hnsn-oss.2vm.co/",
    pro: "https://oss.hnsn.vip/",
    uat: "https://hnsn-oss.2vm.co/"
  }
};
