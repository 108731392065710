<template>
  <a-layout class="app" id="components-layout-demo-fixed">
    <a-back-top />
    <app-header
      :breadCrumb='breadCrumb' 
      :menuToggle='menuToggle'
      :menuClick='menuClick' 
      :avatar='avatar' :loginOut='loginOut'
    ></app-header>
    <app-aside :style="{paddingTop:'64px', height: '100vh',overflowY: 'auto'}" :menuToggle='menuToggle' :menu='menu'></app-aside>
    <a-layout :style="{paddingTop:'64px', height: '100vh',overflowY: 'scroll'}">
        <a-layout-content class="content">
          <router-view :key="key" />
          <app-footer></app-footer>
        </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<style lang="less">
@import '../style/layout.less';
</style>
<script>
import {mapState} from 'vuex'
import AppAside from './AppAside'
import AppHeader from './AppHeader'
import AppFooter from './AppFooter'
import avatar from '@/assets/images/user.png'
import {sendLogout} from '@/api/login';
import {$iscode} from '@/utils/app';
//返回除了首页之外的面包屑
const getBreadCrumb = (pathname,menuTree,crumb) => {
  // 首页返回false
  if(pathname === '/index') return false;
  // 递归遍历远端导航菜单tree
  for(let i = 0; i< menuTree.length; i++){
    // 符合则添加到面包屑中
    if(pathname.search(menuTree[i].key) === 0){
      if(menuTree[i].key === pathname){
        crumb.unshift(menuTree[i].title);
        return true;
      }else {
        // 不符合如果有子集继续查找
        if(menuTree[i].children && menuTree[i].children.length>0){
          let state = getBreadCrumb(pathname, menuTree[i].children, crumb);
          if(state){
            crumb.unshift(menuTree[i].title);
            return true;
          }
        }
      }
    }
  }
  return false;
};
export default {
  name: 'DefaultLayout',
  components: {
    AppAside,
    AppHeader,
    AppFooter
  },
  computed: {
    key() {
      return this.$route.path
    },
    ...mapState({
      menu: state => state.user.info.menu,
      menuToggle: state=>state.app.menuToggle,
    }),
    breadCrumb() {
      let breadCrumb = [];
      getBreadCrumb(this.$route.path,this.menu,breadCrumb);
      return breadCrumb;
    },
  },
  data() {
    return {
      avatar,
    }
  },
  mounted() {
  },
  methods: {
    menuClick() {
      this.$store.dispatch('app/setAppMenuToggle',!this.menuToggle);
    },
    async loginOut() {
      try{
        let res = await sendLogout();
        if($iscode(res,true)){
          localStorage.clear();
          location.href = '/login';
        }
      }catch(e){
        localStorage.clear();
        location.href = '/login';
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="less" scoped>
.fade-enter {
  opacity: 0;
}
.fade-leave {
  opacity: 1;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s;
}
</style>