<template>
  <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
    :onFinish='pageSearchChange' layout='inline'>
    <a-form-item label="关键词" name='keywords'>
      <a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入关键词进行搜索"></a-input>
    </a-form-item>
    <a-form-item>
      <a-button type="primary" htmlType='submit'>搜索</a-button>
    </a-form-item>
  </a-form>
  <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data'>
    <template v-slot:action="{ record }">
      <a-button type="primary" @click='select(record)' v-if='!checkout' :ghost="!record.select">{{!record.select ?
        "选择":"已选择"}}</a-button>
      <a-button type="primary" @click='checkBnt(record)' :ghost="!record.select" v-else>{{!record.select ? "选择":"已选择"}}
      </a-button>
    </template>
  </a-table>
  <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page"
    :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange"
    :show-total="total => `总数: ${total} `" />
  <div class="s_flex_center m-t-10" v-if='checkout'>
    <a-button type="primary" @click='confirm'>确定</a-button>
  </div>
</template>
<script>
  import { ref, onMounted, reactive, toRefs } from "vue";
  import { message } from "ant-design-vue";
  import { $iscode } from "@/utils/app";
  import { noticeList } from "@/api/brand";
  // 初始化默认筛选项数值
  let defSearch = {
    keywords: '',
    send_type: 1,
    start_time: '',
    end_time: '',
    status: 1,
    page: 1,
    limit: 5
  };
  export default {
    name: "selectNotice",
    props: {
      selectList: {
        type: Array
      },
      checkout: {
        type: Boolean
      },
    },
    setup(props, { emit }) {
      const state = reactive({
        listLoading: false,
        total: 0
      });
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      let columns = [
        {
          title: "标题",
          dataIndex: "title",
        },
        {
          title: "通知对象",
          dataIndex: "send_type",
          customRender: function ({ record }) {
            return record.send_type == 1 ? '全部品牌' : '指定品牌'
          }
        },
        {
          title: "发布人",
          dataIndex: "publisher",
        },
        {
          title: "发布状态",
          dataIndex: "status",
          customRender: function ({ record }) {
            return record.status ? '已发布' : '待发布'
          }
        },
        {
          title: "发布时间",
          dataIndex: "create_time",
          customRender: function ({ record }) {
            return record.create_time ? record.create_time : '-'
          }
        },
        {
          title: "操作",
          dataIndex: "action",
          slots: { customRender: 'action' },
        },
      ];
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };
      const initData = async (values) => {
        state.listLoading = true;
        data.value = [];
        try {
          let res = await noticeList({ ...values }).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
            if (props.checkout) {
              state.checkout = props.checkout
            }
            if (state.selectList || state.selectList.length > 0) {
              data.value.forEach(i => {
                if (state.selectList.find(v => {
                  return i.id == v.id || i.id == v.store_id
                })) {
                  i.select = true
                }
              })
            }
          } else {
            message.error(res.message);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      onMounted(() => {
        if (props.selectList) {
          state.selectList = JSON.parse(JSON.stringify(props.selectList))
        }
        initData(search.value);
      });
      //单选
      const select = (e) => {
        data.value.map(item => item.select = false)
        if (e != 0) {
          e.select = !e.select
        }
        emit("select", e);
      }
      //多选
      const checkBnt = (e) => {
        if (!e.select) {
          state.selectList.push(e);
        } else {
          var index = 0;
          state.selectList.forEach((v, k) => {
            if (v.id == e.id) {
              index = k;
            }
          });
          state.selectList.splice(index, 1);
        }
        e.select = !e.select
      }
      //多选确认
      const confirm = () => {
        emit("select", state.selectList);
      }
      return {
        ...toRefs(state),
        search,
        data,
        columns,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        select,
        checkBnt,
        confirm,
      };
    },
  };
</script>