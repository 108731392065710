/* 声明  auth 注明 模块名称 权限
/  如:
		产品管理
		product_spendList_modulespend_list
		分别意思是  产品模块_二级模块_三级模块_list权限
		列表 ==》 list
		按钮类型的权限最后面 一定要跟一个标识
		添加 ==》 add
		删除 ==》 del
		审核 ==》 review
		详情 ==》 detail
		修改 ==》 detail
		...未完

*/

const menu = [{
	key: '/index',
	title: '首页',
	icon: 'HomeOutlined',
	component: "Index",
	auth: "index"
},
{
	title: '产品管理',
	key: '/product',
	icon: 'GiftOutlined',
	auth: 'product',
	children: [{
		title: '产品定价列表',
		key: '/product/product',
		auth: 'product_spendList',
		icon: '',
		children: [{
			title: '功能模块费用',
			key: '/product/product/product_spend',
			auth: 'product_spendList_modulespend_list',
			icon: '',
			component: 'product/product'
		}]
	},
	{
		title: '短信定价列表',
		key: '/product/product/sms',
		auth: 'smsPriceManage',
		component:'product/sms',
		icon: '',
		children: [{
			title: '添加短信定价',
			key: 'smsPriceAdd',
			auth: 'smsPriceAdd',
			hidden:true
		},
		{
			title: '修改短信定价',
			key: 'smsPriceEdit',
			auth: 'smsPriceEdit',
			hidden:true
		},
		{
			title: '删除短信定价',
			key: 'smsPriceDel',
			auth: 'smsPriceDel',
			hidden:true
		}]
	}
	,
	{
		title: '产品销售明细',
		key: '/product/product/productLog',
		auth: 'productLog',
		component:'product/productLog',
		icon: '',
		children: [{
			title: '产品销售明细详情',
			key: 'productLogDetail',
			auth: 'productLogDetail',
			hidden:true
		},
		{
			title: '关联应用',
			key: 'productLogSee',
			auth: 'productLogSee',
			hidden:true
		}
		]
	}]
},
{
	title: '财务管理',
	key: '/financial',
	auth: "financial",
	icon: 'PayCircleOutlined',
	children: [{
		title: '代理商账户管理',
		key: '/financial/management',
		icon: '',
		auth: "financial_management_list",
		component: "financial/management/index"
	},
	{
		title: '收支明细',
		key: '/financial/payments',
		auth: "financial_payments",
		icon: '',
		children: [{
			title: '我的品牌商',
			key: '/financial/payments/index',
			auth: "financial_payments_brand_list",
			icon: '',
			component: "financial/payments/index"
		}]
	},
	{
		title: '代理商充值记录',
		key: '/financial/recharge',
		icon: '',
		component: "financial/recharge/index"
	},
	{
		title: '分账收入明细',
		key: '/financial/splitAccount',
		auth: "financial_splitAccount",
		icon: '',
		component: "financial/splitAccount/index"
	},
	{
		title: '用户余额查询',
		key: '/financial/balanceQuery',
		auth: "financial_balance_query",
		icon: '',
		component: "financial/balanceQuery/index"
	},
	]
},
{
	title: '代理商管理',
	key: '/agent',
	auth: "agent",
	icon: 'TeamOutlined',
	children: [{
		title: '代理商列表',
		key: '/agent/agent_list',
		auth: "agent_list",
		component: "agent/agent_list",
		icon: '',
	},
	{
		title: '代理商详情',
		auth: "agent_detail",
		key: '/agent/agent_list/detail',
		component: "agent/agent_detail",
		hidden: true
	},
	{
		title: '代理商等级',
		auth: "agent_agentlevel_list",
		key: '/agent/agent_level',
		icon: '',
		component: "agent/agent_level",
	},
	{
		title: '代理商详情',
		auth: "agent_agentlevel_detail",
		key: '/agent/agent_level/detail',
		component: "agent/add_level",
		hidden: true
	}
	]
},
{
	title: '品牌商管理',
	key: '/brand',
	auth: "brand",
	icon: 'SolutionOutlined',
	children: [{
		title: '品牌商列表',
		key: '/brand/brand_list',
		auth: "brand_list",
		component: "brand/brand_list",
		icon: '',
	},
	{
		title: '品牌商添加',
		key: '/brand/brand_create',
		auth: "brand_create",
		component: "brand/brand_create",
		icon: '',
	},
	{
		title: '品牌商禁用审核',
		key: '/brand/brand_disable',
		auth: "brand_review",
		component: "brand/brand_disable",
		icon: '',
	},
	{
		title: '消息模板审核',
		key: '/brand/brand_auditMessage',
		auth: 'brand_auditMessage_review',
		component: "brand/brand_auditMessage",
		icon: '',
	},
	{
		title: '品牌商详情',
		key: '/brand/brand_list/detail',
		auth: "brand_detail",
		component: "brand/brand_detail",
		hidden: true
	}
	]
},
{
	title: '品牌商消息管理',
	key: '/brandMsg',
	icon: 'AppstoreOutlined',
	auth: "brandMsg",
	children:[
		{
			title: '系统公告',
			key: '/brandMsg/announcement',
			auth: "systemAnnouncement",
			component: "brandMsg/announcement/index",
			children:[
				
				{
					title: '添加系统公告',
					key: '/brandMsg/announcement/announcement_add',
					auth: "addAnnouncementDetails",
					component: "brandMsg/announcement/announcement_details",
					hidden:true
				},
				{
					title: '查看系统公告',
					key: '/brandMsg/announcement/announcement_look',
					auth: "lookAnnouncementDetails",
					component: "brandMsg/announcement/announcement_details",
					hidden:true
				}
			]
		},
		{
			title: '短信推送模板',
			key: '/brandMsg/smsTemplate',
			auth: "smsTemplate",
			component: "brandMsg/smsTemplate/index",
			children:[
				{
					title: '添加短信推送模板',
					key: '/brandMsg/smsTemplate/add',
					auth: "smsTemplateAdd",
					component: "brandMsg/smsTemplate/add",
					hidden:true
				},
				{
					title: '编辑短信推送模板',
					key: '/brandMsg/smsTemplate/edit',
					auth: "smsTemplateEdit",
					component: "brandMsg/smsTemplate/add",
					hidden:true
				},
			]
		},
		{
			title: '小程序推送模板',
			key: '/brandMsg/miniTemplate',
			auth: "miniTemplate",
			component: "brandMsg/miniTemplate/index",
			children:[
				{
					title: '添加小程序推送模板',
					key: '/brandMsg/miniTemplate/add',
					auth: "miniTemplateAdd",
					component: "brandMsg/miniTemplate/add",
					hidden:true
				},
				{
					title: '编辑小程序推送模板',
					key: '/brandMsg/miniTemplate/edit',
					auth: "miniTemplateEdit",
					component: "brandMsg/miniTemplate/add",
					hidden:true
				},
			]
		},
		{
			title: '公众号推送模板',
			key: '/brandMsg/publicTemplate',
			auth: "publicTemplate",
			component: "brandMsg/publicTemplate/index",
			children:[
				{
					title: '添加公众号推送模板',
					key: '/brandMsg/publicTemplate/add',
					auth: "publicTemplateAdd",
					component: "brandMsg/publicTemplate/add",
					hidden:true
				},
				{
					title: '编辑公众号推送模板',
					key: '/brandMsg/publicTemplate/edit',
					auth: "publicTemplateEdit",
					component: "brandMsg/publicTemplate/add",
					hidden:true
				},
			]
		},
		{
			title: '短信模板审核',
			key: '/brandMsg/smsReviewed',
			auth: "smsReviewed",
			component: "brandMsg/smsReviewed/index",
		},
		{
			title: '短信签名审核',
			key: '/brandMsg/signReviewed',
			auth: "signReviewed",
			component: "brandMsg/signReviewed/index",
		},
		{
			title: '短信统计',
			key: '/brandMsg/smsStatistics',
			auth: "smsStatistics",
			component: "brandMsg/smsStatistics",
			children: [
				{
					title: '统计概览',
					key: '/brandMsg/smsStatistics/overview',
					auth: "smsStatisticsOverview",
					component: "brandMsg/smsStatistics/overview/index",
				},
				{
					title: '短信记录',
					key: '/brandMsg/smsStatistics/smsRecord',
					auth: "smsStatisticsRecord",
					component: "brandMsg/smsStatistics/smsRecord/index",
				}
			]
		},
	]
},
{
	title: '类目管理',
	key: '/brandMsg',
	icon: 'CommentOutlined',
	auth: "brandMsg",
	children: [
		{
			title: '类目列表',
			key: '/category/index',
			component: "category/index",
			icon: '',
		}
	]
},
{
	title: '小程序代码管理',
	key: '/appletCode',
	icon: 'AppstoreOutlined',
	auth: "appletCode",
	children: [
		{
			title: '系统域名管理',
			key: '/appletCode/baseUrl/index',
			auth: 'systemMiniUrl',
			component: "appletCode/baseUrl/index",
			icon: '',
		},
		{
			title: '小程序代码模板管理',
			key: '/appletCode/appletCodeTemplate/index',
			auth: 'appletCodeTempalte',
			component: "appletCode/appletCodeTemplate/index",
			icon: '',
		},
		{
			title: '小程序代码发布',
			key: '/appletCode/appletRelease/index',
			component: "appletCode/appletRelease/index",
			icon: '',
			auth: "appletRelease",
			children: [
				{
					title: '小程序代码发布',
					key: 'appletCodePut',
					icon: '',
					auth: "appletCodePut",
					hidden: true
				}
			]
		}
		,
		{
			title: '小程序版本详情',
			key: '/appletCode/appletDetail/index',
			component: "appletCode/appletDetail/index",
			icon: '',
			auth: "appletCodeRelease"
		}
		,
		{
			title: '小程序代码同步记录',
			key: '/appletCode/appletReleaseLogs/index',
			component: "appletCode/appletReleaseLogs/index",
			icon: '',
			auth: "appletReleaseLogs",
			children: [
				{
					title: '小程序代码撤销审核',
					key: 'auditrecall',
					icon: '',
					auth: "auditrecall",
					hidden: true
				},
				{
					title: '重新审核',
					key: 'appletCodeReview',
					icon: '',
					auth: "appletCodeReview",
					hidden: true
				}

			]
		}
	]
},

{
	title: '系统管理',
	key: '/system',
	auth: 'system',
	icon: 'SettingOutlined',
	children: [{
		title: '功能权限设置',
		key: '/system/auth_seting',
		auth: 'system_authseting',
		children: [{
			title: '菜单管理',
			auth: 'system_authseting_menusmanage_list',
			key: '/system/auth_seting/menusManage',
			component: "system/menusManage/index",
		},
		{
			title: '角色管理',
			auth: 'system_authseting_roles_list',
			key: '/system/auth_seting/roles',
			component: "system/roles/index",
		},
		{
			title: '管理员管理',
			auth: 'system_authseting_admin_list',
			key: '/system/auth_seting/admin_list',
			component: "system/admin/index",
			icon: '',
		},
		]
	},
	{
		title: '添加管理员',
		key: '/system/admin_create',
		auth: 'system_authseting_admin_add',
		component: "system/admin/admin_create",
		hidden: true
	},
	{
		title: '操作日志',
		key: '/system/action',
		auth: 'system_authseting_action_list',
		icon: '',
		component: "system/action/index",
	},
	{
		title: '基础设置',
		key: '/system/setting',
		auth: 'system_setting',
		component: "system/setting/index",
	}

	]
},
	{
		title: '意见反馈',
		key: '/feedback/index',
		component: "feedback/index",
		auth: 'feedback',
		icon: 'EditOutlined',
		children: [
			{
				title: '反馈详情',
				key: '/feedback/more',
				component: "feedback/more",
				auth: 'feedback',
				hidden:true
			}
		]
	},
	{
		title: '物流公司管理',
		key: '/system/logistics',
		auth: 'logisticsAdministration',
		component: "system/logistics/index"
	},
	{
		title: '地区信息管理',
		key: '/system/regional',
		auth: 'regionalManagement',
		component: "system/regionalManagement/index",
		children: [
			{
				title: '新增地区信息',
				key: '/system/regional/add_regional',
				component: "system/regionalManagement/regionalDetail",
				auth: 'regionalAdd',
				hidden:true
			},
			{
				title: '修改地区信息',
				key: '/system/regional/edit_regional',
				component: "system/regionalManagement/regionalDetail",
				auth: 'regionalEdit',
				hidden:true
			}
		]
	}

];
const authList = []
module.exports = menu
