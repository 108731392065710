<template>
<a-layout-sider :theme='theme' class='aside' :collapsed='menuToggle' >
    <slider-menu :menuToggle='menuToggle' :theme='theme' :menu="menu"></slider-menu>
</a-layout-sider>
</template>

<script>
import SliderMenu from './SliderMenu.vue';
export default {
    name: 'AppAside',
    components: {
        SliderMenu
    },
    props: {
        menuToggle: {
            required: true,
            type: Boolean
        },
        menu: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            theme: 'light'
        }
    }
}
</script>
