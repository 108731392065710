import axios from '@/utils/request'
import { setToken } from '../libs/util';
export const sendLogin = (data)=>{
 return axios.request({
    url: 'admin/login',
    method: 'post',
    data
  })
};
export const sendLogout = (data)=>new Promise((resolve)=>{
  setToken('')
  resolve({
    code: 1,
    msg: '登出成功',
    data: {}
  })
});
export const sendUserInfo = (data)=>{ 
  // let token = localStorage.getItem('token');
return axios.request({
  url: 'admin/user',
  method: 'get'
})
}

