import axios from "axios";
import QS from "qs";
import { $iscode } from "./app";
import config from "@/config";
import { setToken, getToken } from "@/libs/util";
import router from "@/routers";

export const baseUrl = config.baseUrl[process.env.VUE_APP_type];
class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }
  interceptors(instance) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        const { data, status } = res;
        if (data.code === -14 || data.code === 10011) {
          router.push({ path: "/login" });
          setToken("");
          return;
        }
        return res;
      },
      (error) => {
        $iscode(
          {
            code: 0,
            msg: error.response.data.msg
          },
          true
        );
        return Promise.reject(error);
      }
    );
  }
  request(options) {
    const instance = axios.create();
    let apiAuth = getToken();
    if (apiAuth === false) {
      options = Object.assign(
        {
          baseURL: this.baseUrl,
          headers: {}
        },
        options
      );
    } else {
      options = Object.assign(
        {
          baseURL: this.baseUrl,
          headers: {
            apiAuth: getToken()
          }
        },
        options
      );
    }
    this.interceptors(instance);
    return instance(options);
  }
}

const api_axios = new HttpRequest(baseUrl);
export default api_axios;
