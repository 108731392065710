<template>
  <div ref='editor'></div>
  <!-- <div class='imageChoose' style="opacity: 0;transform: translate(-10000px);"> -->
    <!-- <material :groupType='1' :max="9" ref='imageChoose' v-on:handlechoose="handlechoose"/> -->
  <!-- </div> -->
</template>
<script>
import { onMounted, onBeforeUnmount, ref, reactive, toRefs, watch } from "vue";
import WangEditor from "wangeditor";
import store from "@/store";
import { message } from 'ant-design-vue';
import {imgUpload} from "@/api/brand";
import { $iscode } from "@/utils/app";
const { $, BtnMenu } = WangEditor;
class imgMenu extends BtnMenu {
  constructor(editor) {
    const $elem = WangEditor.$(
      `<div class="w-e-menu"><i class="w-e-icon-image"></i></div>`
    );
    super($elem, editor);
  }
  // 菜单点击事件
  clickHandler(e) {
    // var el = document.querySelectorAll(".imageChoose .ant-upload-select-picture-card");
    // el[el.length - 1].click();
    //  (data)=>{form.image.push(...data)}
  }
  // 菜单是否被激活（如果不需要，这个函数可以空着）
  // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
  // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
  tryChangeActive() {
    // 激活菜单
    // 1. 菜单 DOM 节点会增加一个 .w-e-active 的 css class
    // 2. this.
    if(this.isActive === true){
      this.unActive()
    }else{
    this.active();
    }
    // // 取消激活菜单
    // // 1. 菜单 DOM 节点会删掉 .w-e-active
    // // 2. this.this.isActive === false
    // 
  }
}
export default {
  name: "quill",
  props: {
    value: String,
    height:Number,
    content:String
  },
  setup(props, { emit }) {
    const editor = ref();
    const content = reactive({
      html: props.value,
      text: props.value,
      imageList: [],
    });
    let instance;
    onMounted(() => {
      instance = new WangEditor(editor.value);
       
       
    
      // 注册菜单
      const menuKey = "imgKey"; // 菜单 key ，各个菜单不能重复
      instance.menus.extend("imgKey", imgMenu);
      if(props.height)instance.config.height = props.height
    let watcher= watch(props,() => {
        if(props.content){
          if(!watcher)return
           instance.txt.html(props.content) 
           watcher=null
        }
      })
      Object.assign(instance.config, {
        showLinkImg: false,
        menus: [
          "head",
          "bold",
          "fontSize",
          "fontName",
          "italic",
          "underline",
          "strikeThrough",
          "indent",
          "lineHeight",
          "foreColor",
          "backColor",
          "link",
          "list",
          "justify",
          "quote",
          "table",
          "code",
          "splitLine",
          'image'
        ],
      });
      instance.config.fontSizes = {
            'x-small': { name: '10px', value: '1' },
            'small': { name: '13px', value: '2' },
            'normal': { name: '16px', value: '3' },
            'large': { name: '18px', value: '4' },
            'x-large': { name: '24px', value: '5' },
            'xx-large': { name: '32px', value: '6' },
            'xxx-large': { name: '48px', value: '7' },
        }
       instance.config.lineHeights = ['1', '1.15', '1.6', '2', '2.5', '3']
       instance.config.customUploadImg =async function(resultFiles, insertImgFn){
       console.log(resultFiles,'22',insertImgFn)
        const hide = message.loading('加载中...')
        var formData = new FormData();
        formData.append('image',resultFiles[0])
        let res = await imgUpload(formData).then(res=>res.data);
        if($iscode(res)){
              let url = res.data[0].dir + '/' + res.data[0].name;
              insertImgFn(url)
                content.html = instance.txt.html();
               emit("update:value", content.html);
        }else{
          message.error(res.msg)
        }
        setTimeout(hide)
         
       }
       
      instance.create();
      if(props.content){
           instance.txt.html(props.content) 
      }
      instance.txt.eventHooks.keyupEvents.push(function (e) {
        content.html = instance.txt.html();
        emit("update:value", content.html);
        emit("change", content.html);
      });
    });
    onBeforeUnmount(() => {
      instance.destroy();
      instance = null;
    });
    const syncHTML = () => {};
    const handlechoose = (data) => {
      data.forEach((element) => {
        instance.txt.append(
          `<img src="${store.state.user.info.picdomain + "/" + element}"/>`
        );
      });
      content.html = instance.txt.html();
      emit("update:value", content.html);
      emit("change", content.html);
    };


    return {
      ...toRefs(content),
      syncHTML,
      editor,
      handlechoose,
    };
  },
};
</script>