const state = {
  menuToggle: false,
}

const mutations = {
  SET_APP_MENU_TOGGLE: (state, toggle) => {
    state.menuToggle = toggle;
  },
}

const actions = {
  setAppMenuToggle({ commit }, toggle) {
    commit('SET_APP_MENU_TOGGLE', toggle)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
