<template>
  <a-layout-footer class='footer'>技术支持：广东中源数字化技术有限公司</a-layout-footer>
</template>
<script>
export default {
  name: "AppFooter",
  props: {},
  data() {
    return {};
  },
};
</script>