import axios from "@/utils/request";
// 同步小程序模板列表
export const getMiniappListsync = (data) => {
  return axios.request({
    url: "miniapp/template/sync",
    method: "post",
    data
  });
};
// 同步小程序草稿列表
export const getMiniappDraftsync = (data) => {
  return axios.request({
    url: "miniapp/draft/sync",
    method: "post",
    data
  });
};

// 获取小程序模板列表
export const getMiniappList = (params) => {
  return axios.request({
    url: "miniapp/template/index",
    method: "get",
    params
  });
};
// 小程序模板删除
export const delMiniapp = (data) => {
  return axios.request({
    url: "miniapp/template/delete",
    method: "post",
    data
  });
};
// 获取小程序草稿箱列表
export const getMinidraftList = (params) => {
  return axios.request({
    url: "miniapp/draft/index",
    method: "get",
    params
  });
};
// 小程序草稿箱添加至模板列表
export const addTemplate = (data) => {
  return axios.request({
    url: "miniapp/template/save",
    method: "post",
    data
  });
};
// 编辑小程序模板
export const editTemplate = (id, data) => {
  return axios.request({
    url: "miniapp/draft/edit/" + id,
    method: "post",
    data
  });
};
// 小程序已授权列表
export const getMiniappAuthorize = (params) => {
  return axios.request({
    url: "miniapp/authorize/index",
    method: "get",
    params
  });
};
// 已授权小程序版本详情
export const getAppletEdition = (params) => {
  return axios.request({
    url: "miniapp/authorize/appletEdition",
    method: "get",
    params
  });
};

// 已授权小程序版本详情
export const getAppletDomain = (params) => {
  return axios.request({
    url: "miniapp/domain/setDomain",
    method: "get",
    params
  });
};

// 已授权小程序域名详情
export const getAppletDomainList = (params) => {
  return axios.request({
    url: "miniapp/domain_use/domain",
    method: "get",
    params
  });
};
//  同步系统域名
export const sysDomainSynchro = (data) => {
  return axios.request({
    url: "miniapp/domain/synchro",
    method: "post",
    data
  });
};
export const setAppletDomain = (data) => {
  return axios.request({
    url: "miniapp/domain/setDomain",
    method: "POST",
    data
  });
};

// 小程序上传代码
export const miniCodeWhole = (data) => {
  return axios.request({
    url: "miniapp/code/whole",
    method: "post",
    data
  });
};

// 小程序发布
export const miniCodePutWhole = (data) => {
  return axios.request({
    url: "miniapp/release/whole",
    method: "post",
    data
  });
};
// 小程序发布记录
export const miniCodebatchIndex = (params) => {
  return axios.request({
    url: "miniapp/release/batchIndex",
    method: "get",
    params
  });
};
//  同步记录列表
export const getMiniappBatchIndex = (params) => {
  return axios.request({
    url: "miniapp/code/batchIndex",
    method: "get",
    params
  });
};
//  同步记录详情列表
export const getMiniappBatchDetail = (params) => {
  return axios.request({
    url: "miniapp/code/index",
    method: "get",
    params
  });
};

//  提交审核
export const submitAuditwhole = (data) => {
  return axios.request({
    url: "miniapp/audit/whole",
    method: "post",
    data
  });
};

//  加急审核
export const submitAuditSpeed = (data) => {
  return axios.request({
    url: "miniapp/audit/speed",
    method: "post",
    data
  });
};
//  撤销审核
export const submitAuditrecall = (data) => {
  return axios.request({
    url: "miniapp/audit/recall",
    method: "post",
    data
  });
};

//  审核记录列表
export const getsubmitAuditAll = (params) => {
  return axios.request({
    url: "miniapp/audit/batchIndex",
    method: "get",
    params
  });
};

export const getAuditDetail = (params) => {
  return axios.request({
    url: "miniapp/audit/index",
    method: "get",
    params
  });
};

//  获取系统服务器域名列表
export const getSysdomain = (params) => {
  return axios.request({
    url: "miniapp/sysdomain/index",
    method: "get",
    params
  });
};

//  添加系统服务器域名列表
export const sysdomainSave = (data) => {
  return axios.request({
    url: "miniapp/sysdomain/setDomain",
    method: "post",
    data
  });
};

export const getExperienceQrcode = (data) => {
  return axios.request({
    url: "miniapp/code/getExperienceQrcode",
    method: "post",
    data
  });
};

//  配置小程序用户隐私保护指引
export const privacySetting = (data) => {
  return axios.request({
    url: "miniapp/privacy/setting",
    method: "post",
    data
  });
};
//  版本回退
export const miniappRevert = (data) => {
  return axios.request({
    url: "miniapp/release/revert",
    method: "post",
    data
  });
};

//  获取小程序用户隐私保护指引
export const getPrivacy = (params) => {
  return axios.request({
    url: "miniapp/privacy",
    method: "get",
    params
  });
};

//  获取小程序接口权限列表
export const getPrivacyList = (params) => {
  return axios.request({
    url: "miniapp/getPrivacyList",
    method: "get",
    params
  });
};

//  设置小程序接口权限
export const setApplyPrivacy = (data) => {
  return axios.request({
    url: "miniapp/applyPrivacy",
    method: "post",
    data
  });
};

//  查询当月提审限额（quota）和加急次数
export const getQuota = (params) => {
  return axios.request({
    url: "miniapp/audit/getQuota",
    method: "get",
    params
  });
};
//  消费者会员小程序-列表
export const getCommunityMIniApp = (params) => {
  return axios.request({
    url: "v2/communityMIniApp",
    method: "get",
    params
  });
};

//  更新授权消费者会员小程序信息
export const updateCommunityMIniApp = (data) => {
  return axios.request({
    url: "v2/communityMIniApp/update",
    method: "post",
    data
  });
};
//  渠道社区小程序-列表
export const getChannelMIniApp = (params) => {
  return axios.request({
    url: "v2/channelMIniApp",
    method: "get",
    params
  });
};
//  更新授权渠道社区小程序信息
export const updateChannelMIniApp = (data) => {
  return axios.request({
    url: "v2/channelMIniApp/update",
    method: "post",
    data
  });
};

//  业务小程序-列表
export const getBusinessMIniApp = (params) => {
  return axios.request({
    url: "v2/channelSaleMiniApp",
    method: "get",
    params
  });
};

//  种植户小程序-列表
export const getGrowerMIniApp = (params) => {
  return axios.request({
    url: "v2/growerMiniApp",
    method: "get",
    params
  });
};
