<template>
    <a-table :pagination='false' :loading='listLoading' rowKey='id' :columns='columns' :data-source='data'>
      <template v-slot:action="{ record }">
        <a-button @click="choose(record)" type="primary">选择</a-button>
      </template>
    </a-table>
    <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange"  :show-total="total => `总数: ${total} `" />
</template>
<script>
import { getMiniappList } from "@/api/appletCode";
import { onMounted, reactive, ref, toRefs } from "vue";
import { $iscode } from '../../utils/app';
// 初始化默认筛选项数值
let defSearch = {
  page: 1,
  limit: 5,
};
export default {
  name:"appletTemplate",
  setup(props,{emit }) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      listLoading: false,
      confirmLoading: false,
      total: 1,
      visible: false,
      active: 1,
    });
    let columns = [
      {
        title: "版本号",
        dataIndex: "user_version",
        key: "user_version",
        width: 120,
      },
      {
        title: "版本描述",
        dataIndex: "user_desc",
        key: "user_desc",
      },
      {
        title: "appID",
        dataIndex: "source_miniprogram_appid",
        key: "source_miniprogram_appid",
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      {
        title: "操作",
        key: "action",
        slots: { customRender: "action" },
      },
    ];
    //小程序列表
    const initData = async (values) => {
      state.listLoading = true;
      let res = await getMiniappList(values).then((res) => res.data);
      state.listLoading = false;
      if ($iscode(res)) {
        console.log(res);
        data.value = res.data.data;
        state.total = res.data.total;
      }
    };
    onMounted(() => {
      initData(search.value);
    });
        // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, limit) => {
      search.value.page = 1;
      search.value.limit = limit;
      initData(search.value);
    };
   const choose=(data)=>{
      emit('success',data)
   }
    return {
      columns,
      search,
      ...toRefs(state),
      data,
      pageSearchChange,
      pageSizeChange,
      pageCurrentChange,
      choose
    };
  },
};
</script>